import React from "react"
import Layout from "@components/layout";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const NotFoundPage = () => {
  // Seo情報設定
  const pathName = '404';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">お探しのページは<br />見つかりませんでした</h1>
        </div>
        <div>
          <div className="max-w-xl mx-auto  md:max-w-5xl px-6 mb-[60px] mt-[50px]">
          <p className="mb-5 text-[16px] text-center">404: Not Found<br />You just hit a route that doesn't exist... the sadness.</p>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default NotFoundPage

